import { LengthAwarePaginator } from '@model/common/length-aware-paginator.model';
import { CreateAccountReceivable, UpdateAccountReceivable } from '@model/tenant/account-receivable';
import { GetAccountStandardLaunchResult } from '@model/tenant/account-standard-launch';
import { Observable } from 'rxjs';
import { AccountReceivableModel } from 'src/app/core/model/accountsReceivable/accountReceivable.model';
import { BankSlipModel } from 'src/app/core/model/bankSlip.model';
import { ClientsModel } from 'src/app/core/model/clients.model';
import { FiscalRpsLoteModel } from 'src/app/core/model/fiscalRpsLote.model';
import { PaymentModel } from 'src/app/core/model/payment.model';
import { UploadAttachmentModel } from 'src/app/core/model/uploadAttachment.model';
import { WorkflowLog } from '../../../../../views/pages/tenant/finances/pages/accounts-receivable/models/workflow-log';
import { ApiService } from '../../api.service';
import { iAccountStandardLaunchResponse } from '../../iResponse/iAccountStandardLaunchResponse';
import { iResponseAccountReceivableCheckFiscalRps } from '../../iResponse/iResponseAccountReceivableCheckFiscalRps';
import { BaseCrud } from '../helpers/baseCrud';

export class AccountsReceivable extends BaseCrud {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getUrl() {
    return `${this.apiService.getApiUrl()}/accounts-receivable`;
  }

  //#region Crud
  create(data: CreateAccountReceivable): Observable<any> {
    return this.apiService.getHttp().post(`${this.getUrl()}`, data);
  }

  update(id: string, data: UpdateAccountReceivable): Observable<any> {
    return this.apiService.getHttp().put(`${this.getUrl()}/${id}`, data);
  }

  cancelAccount(id: string, cancelBankSlipFiscalRps = true, cancelRecurrent = false): Observable<any> {
    return this._update<any>(
      { cancelBankSlipFiscalRps, cancelRecurrent },
      undefined,
      `${this.apiService.getApiUrl()}/accounts-receivable/${id}/cancel`,
    );
  }

  getAll(params?: any): Observable<LengthAwarePaginator<AccountReceivableModel[]>> {
    return this.apiService.getHttp().get<LengthAwarePaginator<AccountReceivableModel[]>>(`${this.getUrl()}`, { params });
  }

  get(entityId: string) {
    return this._get<AccountReceivableModel>(entityId);
  }

  bankSlipInformation(entityId: string) {
    return this.apiService.getHttp().get<AccountReceivableModel>(this.url(entityId, 'bank-slip', 'data'));
  }

  setWorkflow(accountId: string, workflowId: string, updateAll: boolean) {
    return this.apiService.getHttp().patch<AccountReceivableModel>(this.url(accountId, 'workflow'), {
      workflow_id: workflowId,
      update_all: updateAll,
    });
  }

  removeWorkflow(accountId: string, updateAll: boolean) {
    return this.apiService.getHttp().delete<AccountReceivableModel>(this.url(accountId, 'workflow'), {
      body : {
        update_all: updateAll,
      }
    });
  }

  delete(entityId: string) {
    return this._delete<AccountReceivableModel>(entityId);
  }

  massDelete(ids) {
    return this.apiService.getHttp().delete<iAccountStandardLaunchResponse[]>(`${this.getUrl()}/mass-delete`, { body: { ids } });
  }

  sendChargeEmail(id: string) {
    return this._create<BankSlipModel>(
      undefined,
      undefined,
      `${this.getUrl()}/${id}/send-charge-email`,
    );
  }

  //#endregion

  //#region BankSlip
  getBankSlip(id: string) {
    return this._get<BankSlipModel[]>(undefined, undefined, `${this.getUrl()}/${id}/bank-slip`);
  }

  generateBankSlip(id: string, accountsIds: string []) {
    return this._create<BankSlipModel>(
      {
        accounts: accountsIds,
      },
      undefined,
      `${this.getUrl()}/${id}/bank-slip`,
    );
  }

  cancelBankSlip(accountId: string, id: string) {
    return this.api.getHttp().post(this.url(accountId, 'bank-slip', id, 'cancel'), {});
  }

  deleteBankSlip(accountId: string, id: string) {
    return this.api.getHttp().delete(this.url(accountId, 'bank-slip', id));
  }

  triggers<T>(accountId: string) {
    return this.api.getHttp().get<T>(this.url(accountId, 'triggers'));
  }

  manualTrigger<T>(accountId: string, stepId: string) {
    return this.api.getHttp().post<T>(this.url(accountId, 'triggers'), { step_id: stepId });
  }

  manualTemplate<T>(accountId: string, templateId: string) {
    return this.api.getHttp().post<T>(this.url(accountId, 'template'), { template_id: templateId });
  }

  previewTemplate<T>(accountId: string, templateId: string) {
    return this.api.getHttp().post<T>(this.url(accountId, 'template-preview'), { template_id: templateId });
  }

  workflowLogs(accountId: string) {
    return this.api.getHttp().get<WorkflowLog[]>(this.url(accountId, 'workflow-logs'));
  }

  //#endregion

  //#region FiscalRps
  fiscalRpsLotes(id: string) {
    return this._get<FiscalRpsLoteModel[]>(
      undefined,
      undefined,
      `${this.getUrl()}/${id}/fiscal-rps-lote`,
    );
  }

  fiscalRpsLote(id: string, rpsLoteId: string) {
    return this._get<FiscalRpsLoteModel>(
      undefined,
      undefined,
      `${this.getUrl()}/${id}/fiscal-rps-lote/${rpsLoteId}`,
    );
  }

  fiscalRpsDownloadNfe(id: string, rpsLoteId: string, rpsId: string) {
    return this._get<FiscalRpsLoteModel>(
      undefined,
      undefined,
      this.fiscalRpsDownloadNfe_link(id, rpsLoteId, rpsId),
    );
  }

  fiscalRpsDownloadNfe_link(id: string, rpsLoteId: string, rpsId: string) {
    return `${this.getUrl()}/${id}/fiscal-rps-lote/${rpsLoteId}/${rpsId}/downloadNfe`;
  }

  fiscalRpsDownloadXml_link(id: string, rpsLoteId: string, rpsId: string) {
    return `${this.getUrl()}/${id}/fiscal-rps-lote/${rpsLoteId}/${rpsId}/downloadXml`;
  }


  cancelRps(rpsId: string, code: number) {
    return this._update<any>({ code }, undefined, `${this.getUrl()}/cancel-fiscal-rps/${rpsId}`);
  }

  checkFiscalRps(id: string, merge = false) {
    return this._get<iResponseAccountReceivableCheckFiscalRps>(
      undefined,
      undefined,
      `${this.getUrl()}/${id}/check-fiscal-rps?with_merge=${merge}`,
    );
  }

  generateFiscalRps(id: string, data) {
    return this._create<BankSlipModel>(
      data,
      undefined,
      `${this.getUrl()}/${id}/fiscal-rps-lote`,
    );
  }

  //#endregion

  //#region Invoices
  private getAccountReceivablePaymentUrl(id: string) {
    return `${this.getUrl()}/${id}/payments`;
  }

  payments(id: string) {
    return this._getAll<PaymentModel[]>(undefined, `${this.getAccountReceivablePaymentUrl(id)}`);
  }

  pay(id: string, paymentInfo: PaymentModel, file?: UploadAttachmentModel[]) {
    return this._create<AccountReceivableModel[]>(
      paymentInfo,
      file,
      `${this.getAccountReceivablePaymentUrl(id)}`,
    );
  }

  deletePayment(id: string, paymentId: string) {
    return this._delete<PaymentModel[]>(
      undefined,
      `${this.getAccountReceivablePaymentUrl(id)}/${paymentId}`,
    );
  }

  //#endregion

  accountsWithSameDueDate(id: string, type = 'bankSlip') {
    return this._get<AccountReceivableModel[]>(
      undefined,
      undefined,
      `${this.getUrl()}/${id}/accounts-with-same-due-date?type=${type}`,
    );
  }

  getClientInfo(id: string) {
    return this._get<ClientsModel>(undefined, undefined, `${this.getUrl()}/${id}/client-info`);
  }

  getClientService(clientId: string, serviceType: string) {
    return this._get<any[]>(
      undefined,
      `service_type=${serviceType}&client_id=${clientId}`,
      `${this.getUrl()}/clients/services`,
    );
  }

  getAccountStandardLaunch(): Observable<GetAccountStandardLaunchResult[]> {
    return this.apiService.getHttp().get<GetAccountStandardLaunchResult[]>(`${this.getUrl()}/account-standard-launch`);
  }

  getClients() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/clients`);
  }

  getInterns() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/interns`);
  }

  getEmployees() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/employees`);
  }

  getProviders() {
    return this._get<{ id: string; name: string }[]>(undefined, undefined, `${this.getUrl()}/providers`);
  }
}
